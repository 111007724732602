<template>
  <div>
    <el-upload
      v-if="showBtn"
      class="upload-demo"
      action=""
      accept="doc,docx"
      :auto-upload="false"
      :show-file-list="false"
      :on-change="handlerArtSuccess"
    >
      <el-button icon="el-icon-upload2" plain size="mini" type="primary">导入</el-button>
      <div slot="tip" class="el-upload__tip">（只能导入.docx，.doc文件）</div>
    </el-upload>
    <div style="border: 1px solid #ccc; margin-top: 10px">
      <!-- 工具栏 -->
      <Toolbar style="border-bottom: 1px solid #ccc" :editor="editor" :defaultConfig="toolbarConfig" />
      <!-- 编辑器 -->
      <Editor
        :style="{ height: height, overflowY: 'hidden' }"
        :defaultConfig="editorConfig"
        v-model="html"
        @onChange="onChange"
        @onCreated="onCreated"
      />
    </div>
    <el-link v-show="content && content != '<p><br></p>'" :underline="false" size="small" type="primary" @click="clearContent">（清空）</el-link>
  </div>
</template>

<script>
import { nanoid } from 'nanoid'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { DomEditor } from '@wangeditor/editor'
import { getUploadConfigApi } from './api'
import * as mammoth from 'mammoth'
import * as qiniu from 'qiniu-js'
export default {
  name: 'MyEditor',
  components: { Editor, Toolbar },
  props: {
    content: {
      type: String,
      default: ''
    },
    showBtn: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: '300px'
    }
  },
  data() {
    let that = this
    return {
      editor: null,
      html: '',
      toolbarConfig: {
        /* 显示哪些菜单，如何排序、分组 */

        toolbarKeys: [
          'headerSelect',
          'bold',
          'underline',
          'italic',
          'through',
          'clearStyle',
          'color',
          'bgColor',
          'fontSize',
          'fontFamily',
          'lineHeight',
          'indent',
          'delIndent',
          'justifyLeft',
          'justifyRight',
          'justifyCenter',
          'justifyJustify',
          'insertImage',
          'deleteImage',
          'editImage',
          'viewImageLink',
          'imageWidth30',
          'imageWidth50',
          'imageWidth100',
          'divider',
          'emotion',
          'insertLink',
          'editLink',
          'unLink',
          'viewLink',
          'header1',
          'header2',
          'header3',
          'header4',
          'header5',
          'todo',
          'redo',
          'undo',
          'bulletedList',
          'numberedList',
          'uploadImage'
        ]
        // excludeKeys: [ ], /* 隐藏哪些菜单 */
      },
      editorConfig: {
        placeholder: '请输入',
        autoFocus: false,
        readOnly: false, // 只读、不可编辑
        // 所有的菜单配置，都要在 MENU_CONF 属性下
        MENU_CONF: {
          uploadImage: {
            async customUpload(file, insertFn) {
              const isPic = file.type.split('/')[0] === 'image'
              const isLt2M = file.size / 1024 / 1024 <= 10
              if (!isPic) {
                that.$message.error('只能上传图片噢~')
              } else if (!isLt2M) {
                that.$message.error('上传图片大小不能超过 10MB!')
              } else {
                let url = ''
                getUploadConfigApi().then(res => {
                  // this.uploadType = res.uploadType
                  // that.uploadConf = res.config
                  // that.uploadConf.key = nanoid()
                  let qiniuToken = res.config.token
                  var key = null
                  let config = {
                    useCdnDomain: true, //表示是否使用 cdn 加速域名，为布尔值，true 表示使用，默认为 false。
                    region: null // 根据具体提示修改上传地区,当为 null 或 undefined 时，自动分析上传域名区域
                  }
                  let putExtra = {
                    fname: '', //文件原文件名
                    params: {}, //用来放置自定义变量
                    mimeType: null
                  }

                  var observable = qiniu.upload(file, key, qiniuToken, putExtra, config)
                  observable.subscribe({
                    complete: result => {
                      // 利用七牛云注册成功后配置的地址 + 七牛云返回唯一的key，访问上传到七牛云的图片。
                      url = `${res.config.domain}/${result.key}`
                      insertFn(url, '', url) // 将图片插入编辑器显示
                    }
                  })
                })
              }
            }
          }
        }
      },
      uploadConf: {}
    }
  },
  watch: {
    content: {
      handler(newV, oldV) {
        if (newV) {
          this.init()
        }
      }
    }
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = this.content
    // }, 1500)
    this.init()
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.html = this.content
      })
    },
    onCreated(editor) {
      this.editor = Object.seal(editor) // 【注意】一定要用 Object.seal() 否则会报错
      // console.log(this.editor.getAllMenuKeys())
    },
    onChange(editor) {
      // console.log('onChange', editor.getHtml()) // onChange 时获取编辑器最新内容
      // const toolbar = DomEditor.getToolbar(editor)
      // console.log("工具栏配置", toolbar.getConfig().toolbarKeys ); // 工具栏配置
      this.$emit('update:content', editor.getHtml())
    },
    getEditorText() {
      const editor = this.editor
      if (editor == null) return
      // console.log(editor.getText()); // 执行 editor API
    },
    printEditorHtml() {
      const editor = this.editor
      if (editor == null) return
      // console.log(editor.getHtml()); // 执行 editor API
    },
    handlerArtSuccess(file) {
      let reader = new FileReader()
      reader.readAsArrayBuffer(file.raw)
      reader.onload = e => {
        const data = e.target.result
        mammoth.convertToHtml({ arrayBuffer: data }).then(res => {
          this.displayResult(res.value)
        })
      }
    },
    displayResult(html) {
      let newHtml = html
        .replace('<h1>', '<h1 style="text-align:center;">')
        .replace('<h2>', '<h2 style="text-align:center;">')
        .replace('<img', '<img style="width:20%;"')
        .replace(/<table>/g, '<table style="border-collapse:collapse;">')
        .replace(/<tr>/g, '<tr style="height:30px;">')
        .replace(/<td>/g, '<td style="border:1px solid #ccc">')
        .replace(/<p>/g, '<p style="text-indent:2em;">')
      // this.content = newHtml
      this.$emit('update:content', newHtml)
      this.init()
    },
    clearContent() {
      this.$emit('update:content', '')
      this.init()
    }
  },

  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁 editor ，重要！！！
  }
}
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.editor-btn {
  display: flex;
}
.el-upload__tip {
  font-size: 12px;
  margin: 0;
  margin-top: 5px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
}
</style>
